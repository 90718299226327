import http from "../utils/http";

export const channelService = {
  list(data) {
    return http.post('channel/list', data)
  },
  detail(data) {
    return http.post('channel/detail', data)
  },
  recommend(data) {
    return http.post('channel/recommend', data)
  }
}
