<template>
  <div class="list-2">
    <!--        <top-menu />-->
    <div class="content">
      <el-card class="box-card">
        <el-form>
          <el-row>
            <el-col :span="8">
              <el-form-item label="实验室名">
                <el-input @keyup.enter.native="getList(true)" v-model="formInline.name" placeholder="请输入实验室名称"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属城市">
                <!--         <el-select v-model="formInline.city" placeholder="活动区域">
                             <el-option label="区域一" value="shanghai"></el-option>
                             <el-option label="区域二" value="beijing"></el-option>
                         </el-select>-->
                <el-cascader
                    v-model="curCity"
                    filterable
                    clearable
                    placeholder="请选择城市"
                    size="medium"
                    :options="options"
                    @keyup.enter.native="getList(true)"
                    @change="handleChange">
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="8" class="search-btn">
              <el-form-item>
                <el-button type="primary" @click="getList(true)">开始检索</el-button>
                <el-button type="primary" plain @click="reset()">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="包含资质">
                <el-checkbox-group v-model="formInline.qualifications" @change="handleCheckedCitiesChange">
                  <el-checkbox v-for="(option, key) in identOptions" :label="key" :key="key">{{ option.text }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card class="box-card list-card">
        <div slot="header" class="clearfix">
          <span><span v-if="formInline.search">搜索关键字“{{ formInline.search }}”,</span>为您找到{{ listData.total }}条数据</span>
        </div>
        <div class="list-item" v-for="item in listData.data" @click="goDetail(item)">
          <div class="left">
            <el-image :src='item.image'/>
          </div>
          <div class="center">
            <div class="ident" v-if="item.is_ident === '1'">
              <img src="@/assets/images/yinzhang.png"/>
            </div>
            <div class="title" v-html="item.name"></div>
            <!--                        <div class="time">-->
            <!--                          平台认证：<el-image src="https://img-static.zhaowoce.com/rating_star.png"/>-->
            <!--                        </div>-->
            <div class="time">
              特殊说明：{{ item.description }}
            </div>
            <div class="time">
              联系电话：{{ item.phone }}
            </div>
            <div class="time">
              所在地址：{{ item.address }}
            </div>
          </div>
          <div class="center_2">
            <el-tag :type="tag.type" v-for="(tag, index) in $formatQuality(item.qualifications)" :key="index"
                    style="margin-right: 5px">
              {{ tag.text }}
            </el-tag>
          </div>
          <div class="right">
            <el-button type="warning" v-if="item.is_ident === '1'"  size="small">机构主页</el-button>
          </div>
        </div>
        <el-empty description="暂无数据" :image-size="100" v-show="listData.data.length === 0"></el-empty>
      </el-card>
      <el-pagination
          style="text-align: center"
          background
          layout="prev, pager, next"
          :current-page="listData.current_page"
          :page-size="Number(listData.per_page)"
          :page-count="listData.last_page"
          @current-change="currentChange"
          :total="listData.total">
      </el-pagination>
    </div>


  </div>
</template>

<script>
import {channelService} from '@/api/channel'
import {provinceAndCityDataPlus, CodeToText} from 'element-china-area-data'
import {setSearchChannelParams, getSearchChannelParams, getPointData} from "@/utils/storageUtils";
import {TAG_TYPES} from '@/utils/const'
import {getKeyWord, setKeyWord} from "../../../utils/storageUtils";

export default {
  name: "list-2",
  props: ['keyWord'],
  data() {
    return {
      formRoute: {},
      options: provinceAndCityDataPlus,
      curCity: [],
      tableData: [],
      formInline: {
        longitude: '',
        latitude: '',
        name: '',
        cityCode: [],
        qualifications: [],
        search: '',
        city: '',
        page: 1,
        pageSize: 10
      },
      listData: {
        current_page: 1,
        isRecommend: false,
        last_page: 1,
        data: [],
        per_page: 1,
        total: 0
      },
      checkAll: false,
      checkedCities: [],
      identOptions: TAG_TYPES,
      isIndeterminate: true
    }
  },
  methods: {
    handleCheckedCitiesChange(value) {
      // console.log('value', value)
      this.getList()
    },
    goDetail(item) {
      // // console.log('item', item)
      if (item.is_ident === '0') return
      this.$router.push({
        query: {
          id: item.id
        },
        name: 'detailLaboratory'
      })
    },
    reset() {
      const point = getPointData()
      this.curCity = []
      this.formInline = {
        cityCode: [],
        latitude: point.lat,
        longitude: point.lng,
        qualifications: [],
        search: '',
        name: '',
        page: 1,
        pageSize: 10
      }
      this.$parent.keyWord = ''
      this.getList()
    },
    formatCity(item) {
      return (() => {
        let province = item[0] ? CodeToText[item[0]] : ''
        let city = item[1] ? CodeToText[item[1]] : ''
        if (city === '市辖区') {
          city = ''
        }
        // if (province.includes('市')) {
        //     province = province.split('市')[0]
        // }
        this.formInline.province = province
        this.formInline.city = city
        if (!province && !city) return ''
        if (province && !city) return province
        return `${province}/${city}`
      })()
    },
    handleChange(item) {
      // // console.log('item', item)
      // // // console.log(CodeToText[item[0]], CodeToText[item[1]])
      this.formInline.cityCode = item
      this.formatCity(item)
    },
    currentChange(page) {
      this.formInline.page = page
      this.getList()
    },
    getList(isSearch) {
      if (isSearch) {
        this.formInline.page = 1
        this.formInline.search = ''
      }
      this.formInline['name'] = this.formInline['name'].replaceAll(' ', '').replaceAll('；', ';')
      let params = {
        ...{},
        ...this.formInline
      }
      /*            if (this.formRoute.name === 'detail') {
                       params = {
                          ...params,
                          ...getSearchChannelParams()
                      }
                  }*/
      // const heightKey = ['city', 'detection_obj', 'institution_name', 'detection_items', 'detection_standard']
      setSearchChannelParams(params)
      channelService.list(params).then(res => {
        /*       res.data.list.forEach(item => {
                   if (params.search) {
                       heightKey.map(key => {
                           if ((key in params) && item[key].includes(params.search)) {
                               item[key] = item[key].replace(params.search, `<em>${params.search}</em>`)
                           }
                       })
                   } else {
                       heightKey.map(key => {
                           if (params[key] && item[key].includes(params[key])) {
                               item[key] = item[key].replace(params[key], `<em>${params[key]}</em>`)
                           }
                       })
                   }
               })*/
        this.listData = res.data
        // // console.log('this.listData', this.listData)
      })
    }
  },
  mounted() {
    // // console.log('this.router', this.$route)
    // const keyWord = getKeyWord()
    // if (keyWord) {
    //   this.formInline.search = keyWord
    //   setKeyWord()
    // }
    this.getList()
  },
  beforeRouteEnter(to, form, next) {

    // to.meta.keepAlive = to.name === 'detail'
    next(vm => {
      vm.formRoute = form
      if (form.name === 'detailLaboratory') {
        vm.formInline = getSearchChannelParams()
        vm.curCity = vm.formInline.cityCode
        // vm.$parent.keyWord = vm.formInline.search
        vm.formInline.city = vm.formatCity(vm.formInline.cityCode)
      } else {
        const point = getPointData()
        vm.formInline.latitude = point.lat
        vm.formInline.longitude = point.lng
        // vm.formInline.search = vm.$parent.keyWord
        if (vm.$parent.keyWordType == 2) {
          vm.formInline.search = vm.$parent.keyWord
        } else {
          vm.formInline[vm.$parent.keyWordType] = vm.$parent.keyWord
        }
      }

    })
  }
}
</script>
<style lang="scss">
.list-2 {
  em {
    color: $danger;
    font-style: normal !important;
  }

  .el-form-item {
    margin: unset;
  }

  .list-card .el-card__body {
    padding: 0 10px;
    overflow: auto;
    height: 80vh;
  }
}
</style>
<style scoped lang="scss">

.list-2 {

  .el-input, .el-cascader {
    width: 70%;
  }

  .content {
    width: 85%;
    //width: 85%;
    height: 100%;
    margin: 0 auto;
  }

  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .box-card {
    /*width: 480px;*/
    margin: 15px 0;

    .search-btn {
      .el-button {
        width: 160px;
        padding: 9px 10px;
      }
    }
  }

  .list-card {
    height: 80vh;
    overflow: auto;
    /*padding: 10px 5px;*/
    ::v-deep.el-card__body {
      padding: 0 10px;
    }

    .list-item {
      cursor: pointer;
      display: flex;
      border-radius: 2px;
      border-bottom: 1px solid $border_2;
      /*align-items: center;*/
      padding: 10px 0;

      .left {
        display: block;
        line-height: 1.5;
        //flex: 0.2;
        ::v-deep .el-image {
          width: 200px;
          height: 100%;
        }

      }

      .center {
        position: relative;
        flex: 0.4;
        /*margin-top: 7px;*/
        margin: 5px 0 0 10px;

        .ident {
          position: absolute;
          top: 20%;
          //left: 10%;
          left: 100px;
          opacity: 0.5;

          img {
            width: 100px;
          }
        }

        .title {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 28px;
        }

        .description {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-top: 6px;
          color: $font_1;
        }

        .search_line {
          color: $font_2;
          font-size: 13px;
        }

        .time {
          font-size: 12px;
          font-weight: 400;
          margin-top: 10px;
          color: $font_2
        }

        .labels {
          border-radius: 2px;

          div {
            /*width: 90px;*/
            /*border:1px solid rgba(184,180,255,1);*/
            font-size: 14px;
            padding: 5px;
            color: $font_2;
          }
        }
      }

      .center_2 {
        display: flex;
        justify-content: left;
        align-items: center;
        flex: 0.5;
      }

      .right {
        flex: 0.1;
        line-height: 1.4;
        text-align: center;
        cursor: pointer;
        display: grid;
        justify-content: right;
        align-items: center;
        margin-right: 30px;

        p {
          color: $danger;
          font-size: 18px;
          font-weight: 500;
          margin: 5px 0;
        }
      }
    }
  }
}

</style>
